import React, { useState } from "react";
import { trim } from "lodash";

import {
	Container,
	Paper,
	TextField,
	Typography,
	Button,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Alert,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { getMediaDownloadPath } from "gatsby-frontend";
import { SiteContext } from "gatsby-frontend/src/Site";
import { fetchAs } from "gatsby-frontend";

const MyPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(3),
	margin: theme.spacing(3, 0),
}));

function EndpointForm({
	defaultEndpoint = null,
	extras,
	setExtras,
	downloads,
	setDownloads,
	userData,
}) {
	const theme = useTheme();
	const [endpoint, setEndpoint] = useState(defaultEndpoint);
	const [loading, setLoading] = useState(false);
	const { dispatch, isAuthenticated } = React.useContext(SiteContext);
	const [alert, setAlert] = useState(null);

	const handleEndpointChange = (e) => {
		setEndpoint(trim(e.target.value));
	};

	const onSubmit = (e) => {
		e.preventDefault();
		async function s() {
			try {
				setLoading(true);
				let _dispatch = isAuthenticated ? dispatch : null;
				const resp = await fetchAs(endpoint, _dispatch, null, null, true);
				if (resp && resp.data) {
					const { data, included, meta } = resp;
					const _data = Array.isArray(data) ? data : data ? [data] : [];
					if (_data.length > 0) {
						let _alert = {
							severity: "success",
							message: "Data received",
							data: _data,
						};
						if (included && Array.isArray(included) && included.length > 0) {
							_alert.included = included;
						}
						let _additional_endpoints = [];
						let _additional_downloads = [];
						if (endpoint.includes("node")) {
							for (const { relationships } of _data) {
								if (
									relationships &&
									relationships.field_media &&
									relationships.field_media.data
								) {
									let rels = Array.isArray(relationships.field_media.data)
										? relationships.field_media.data
										: [relationships.field_media.data];
									for (const a_rel of rels) {
										let _endpoint = `${a_rel.type.replace("--", "/")}/${
											a_rel.id
										}`;
										_additional_endpoints.push(_endpoint);
										let download_path = getMediaDownloadPath(a_rel);
										_additional_downloads.push(
											`${process.env.GATSBY_SITE_URL}${download_path}`
										);
										if (a_rel.meta && a_rel.meta.drupal_internal__target_id) {
											_additional_downloads.push(
												`${process.env.GATSBY_BACKEND_URL}/media/${a_rel.meta.drupal_internal__target_id}`
											);
										}
									}
								}
							}
						} else if (endpoint.includes("media")) {
							for (const { relationships } of _data) {
								if (
									relationships &&
									relationships.field_media_file &&
									relationships.field_media_file.data
								) {
									let _endpoint = `${relationships.field_media_file.data.type.replace(
										"--",
										"/"
									)}/${relationships.field_media_file.data.id}`;
									_additional_endpoints.push(_endpoint);
								}
							}
						} else if (endpoint.includes("file")) {
							for (const { attributes } of _data) {
								if (attributes && attributes.uri && attributes.uri.url) {
									_additional_downloads.push(
										`${process.env.GATSBY_BACKEND_URL}${attributes.uri.url}`
									);
								}
							}
						}
						if (_additional_endpoints.length > 0) {
							setExtras([...extras, ..._additional_endpoints]);
						}
						if (_additional_downloads.length > 0) {
							setDownloads([...downloads, ..._additional_downloads]);
						}
						setAlert(_alert);
					} else {
						let _alert = {
							severity: "error",
							message: "Empty data",
						};
						if (meta) {
							if (meta.omitted) {
								_alert.message = meta.omitted.detail;
							}
							if (meta.omitted.links) {
								for (const [, l] of Object.entries(meta.omitted.links)) {
									if (l.meta && l.meta.detail) {
										_alert.message += `<br /> - ${l.meta.detail}`;
									}
								}
							}
						}
						setAlert(_alert);
					}
				} else if (!resp) {
					setAlert({
						severity: "error",
						message: "Could not fetch",
					});
				}

				setLoading(false);
			} catch (error) {
				console.error(error);
			}
		}
		s();
	};

	return (
		<MyPaper elevation={3} component="form" onSubmit={onSubmit}>
			<div style={{ display: "flex" }}>
				<TextField
					fullWidth
					label="Endpoint"
					defaultValue=""
					variant="outlined"
					onChange={handleEndpointChange}
					value={endpoint}
					disabled={userData === null || alert !== null}
				/>
				<Button
					variant="contained"
					color="secondary"
					sx={{
						minWidth: 200,
						marginLeft: theme.spacing(2),
					}}
					type="submit"
					disabled={
						!(endpoint !== null && endpoint.length > 0) ||
						loading ||
						alert !== null
					}
				>
					Submit
				</Button>
			</div>

			{alert && (
				<Alert
					id={`alert-${alert.severity}`}
					severity={alert.severity}
					sx={{
						marginTop: theme.spacing(2),
						marginBottom: theme.spacing(2),
					}}
				>
					<div dangerouslySetInnerHTML={{ __html: alert.message }}></div>
				</Alert>
			)}

			{alert && alert.data && (
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>data</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<pre
							sx={{
								overflow: "auto",
								border: `${theme.palette.grey["200"]} 1px solid`,
								padding: theme.spacing(2),
								marginBottom: theme.spacing(4),
								backgroundColor: theme.palette.grey["lighter"],
							}}
							id="response-data"
						>
							{JSON.stringify(alert.data, null, "\t")}
						</pre>
					</AccordionDetails>
				</Accordion>
			)}

			{alert && alert.included && (
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>included</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<pre
							sx={{
								overflow: "auto",
								border: `${theme.palette.grey["200"]} 1px solid`,
								padding: theme.spacing(2),
								marginBottom: theme.spacing(4),
								backgroundColor: theme.palette.grey["lighter"],
							}}
							id="response-included"
						>
							{JSON.stringify(alert.included, null, "\t")}
						</pre>
					</AccordionDetails>
				</Accordion>
			)}
		</MyPaper>
	);
}

function TestPage() {
	const theme = useTheme();
	const { userData } = React.useContext(SiteContext);
	const [authLabel, setAuthLabel] = useState("Loading...");

	const [extras, setExtras] = useState([]);
	const [downloads, setDownloads] = useState([]);

	React.useEffect(() => {
		if (userData !== null) {
			let label = "Connected as ";
			if (!userData) {
				label += " anonymous";
			} else {
				if (
					userData?.relationships?.roles?.data &&
					Array.isArray(userData.relationships.roles.data) &&
					userData.relationships.roles.data.length > 0
				) {
					let role_names = userData.relationships.roles.data.map(
						({ meta }) => meta.drupal_internal__target_id
					);
					label += role_names.join(", ");
				}
			}
			setAuthLabel(label);
		}
	}, [userData]);

	return (
		<Container>
			<Typography
				variant="h3"
				sx={{
					marginTop: theme.spacing(3),
					marginBottom: theme.spacing(1),
				}}
			>
				API Requests Tester
			</Typography>
			<Alert
				severity="info"
				sx={{
					marginTop: theme.spacing(2),
					marginBottom: theme.spacing(2),
				}}
			>
				{authLabel}
			</Alert>
			<EndpointForm
				extras={extras}
				setExtras={setExtras}
				downloads={downloads}
				setDownloads={setDownloads}
				userData={userData}
			/>
			{extras.map((e) => (
				<EndpointForm
					defaultEndpoint={e}
					extras={extras}
					setExtras={setExtras}
					downloads={downloads}
					setDownloads={setDownloads}
					userData={userData}
				/>
			))}
			{downloads.map((d) => (
				<MyPaper elevation={3}>
					<Typography component="a" href={d} target="_blank" rel="noreferrer">
						{d}
					</Typography>
				</MyPaper>
			))}
		</Container>
	);
}

export default TestPage;
